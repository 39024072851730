/*=================================================================
  Navigation
==================================================================*/

.navigation {
  background-color: #1d2024;
  width: 100%;
  z-index: 99;
  margin-bottom: 0;
  padding: 10px 0;
  position: sticky;
  top: 0;

  .logo {
    padding: 0;
    height: auto;
    margin-top: 0;

    img {
      height: auto;
      width: 140px;
    }
  }

  .navigation-menu {
    li a.active {
      color: $primary-color !important;
    }

    a {
      padding-left: 15px !important;
      padding-right: 15px !important;
      font-size: 16px;
      color: #737f8a;

      &:hover,
      &:focus {
        background: transparent;
        color: $primary-color !important;
      }
    }
  }
}

.navbar-toggle {
  border: 1px solid $white;
  border-radius: 0;

  span {
    background: $white;
  }
}
